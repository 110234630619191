import './App.css';
import { useState } from 'react';

function App() {

    const [count, setCount] = useState(0);

    const handleClick  = (x) => {
        setCount(count => count + 1);
    }

    return (
        <div>
            {count}
            <button onClick={handleClick}> Click me if u can</button>
        </div>
    );
}

export default App;
